import  { getAuth, signOut, currentUser } from "firebase/auth";
// import {
//   getFirestore,
//   collection,
//   addDoc,
//   setDoc,
//   getDoc,
//   doc,
//   FieldValue,
//   updateDoc,
//   deleteDoc,
//   arrayUnion,
//   query,
//   orderBy,
//   limit,
//   getDocs,
// } from "firebase/firestore";

function userSignOut() {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    //   console.log("Logged out");
    })
    .catch((error) => {
    //   console.log(error);
      // An error happened.
    });
}

function getCurrentUser() {
  const auth = getAuth();
  const user = auth.currentUser;
  return user; 
}



export default {
  userSignOut,
  getCurrentUser,

};