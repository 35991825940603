import React, { useEffect, useState, useContext, createContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import MainNav from "./components/MainNav";

import firebaseConfig from "./config/firebase";

import LoginScreen from "./containers/LoginScreen";
import LoadingScreen from "./containers/LoadingScreen";
import MainScreen from "./containers/MainScreen";
import EditUserScreen from "./containers/EditUserScreen";

import { initializeApp } from "firebase/app";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import "./App.css";

import { UserContext } from "./context/userContext";

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();

function PrivateRoute({
  component: Component,
  userAuthenticated,
  redirect,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        userAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  const { user, setUser } = useContext(UserContext);
  useEffect(() => {
    const unregister = onAuthStateChanged(auth, (usr) => {
      setUser(usr ? usr : {});
    });
    return () => unregister();
  }, []);

  return (
    <Router>
      {!!user?.uid ? <MainNav /> : null}
      {user === null ? (
        <Switch>
          <LoadingScreen />
        </Switch>
      ) : (
        <Switch>
          <PrivateRoute
            path="/login"
            userAuthenticated={!user.uid}
            component={LoginScreen}
            redirect="/"
          />
          <PrivateRoute
            exact
            path="/"
            userAuthenticated={!!user.uid}
            component={MainScreen}
            redirect="/login"
          />
          <PrivateRoute
            exact
            path="/user/:path"
            userAuthenticated={!!user.uid}
            component={EditUserScreen}
            redirect="/login"
          />

          <Route exact path="/">
            <MainScreen />
          </Route>
        </Switch>
      )}
    </Router>
  );
}

export default App;
