import { Form, Input, Button, Space, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const LinkForm = ({linksList, setLinksList}) => {
  const onFinish = values => {
    console.log('Received values of form:', values);
  };
  function handleChange(evt) {
    const value = evt.target.value;
    const indexKey = parseInt(evt.target.getAttribute('indexkey'));
    const name = evt.target.name;
    const newLinksList = [...linksList];
    console.log(indexKey, name, value,'idex')

    const objCopy = newLinksList[indexKey] || {};
    objCopy[name] = value;
    newLinksList[indexKey] = objCopy;
    
    setLinksList(newLinksList);
  };

  function handleItemRemoval(remove, name, key) {
    console.log(remove, name, key)
    const index = parseInt(key)
    remove(name);
    let newLinksList = [...linksList];
    newLinksList.splice(index,1);

    console.log(newLinksList)
    setLinksList(newLinksList);
  }
  return (
    <>
    <Divider plain>Add URLs</Divider>

      <Form.List name="users" initialValue={linksList}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
                
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...restField}
                  label="URL"
                  // name={[name, 'first']}
                  fieldKey={[fieldKey, 'first']}
                  rules={[{ required: true, message: 'Missing URL' }]}
                >
                  <Input indexkey={key} name="url"  addonBefore="https://" placeholder="facebook.com" onChange={handleChange} value={linksList[key]?.url}/>
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Display Name"
                  fieldKey={[fieldKey, 'last']}
                  rules={[{ required: true, message: 'Missing display name' }]}
                >
                  <Input indexkey={key} name="name" placeholder="Facebook" onChange={handleChange} value={linksList[key]?.name}/>
                </Form.Item>
               
                <MinusCircleOutlined onClick={() => handleItemRemoval(remove, name, key)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

    </>
  );
};

export default LinkForm;