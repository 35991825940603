import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";

import "./LoginScreen.css";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function login(email, password) {
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      console.log('Error Code:', errorCode);
      console.log('Error Message:', errorMessage);
    });
}

export default function LoginScreen() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  return (
    <Row className="loginScreen">
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        lg={{ span: 12, offset: 6 }}
      >
        <div className="loginFormContainer">
          <div className="loginFormTitle">
            <h2>Admin Login</h2>
          </div>

          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            style={{
              marginLeft: 0,
              width: "100%",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Form.Item
              label="Username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout} name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => login(username, password)}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
