import "./MainScreen.css";
import React, { useEffect, useState } from "react";
import MainNav from "../../components/MainNav";
import { Layout, Button, Tooltip, Row, Col } from "antd";
import UsersTable from "../../components/UsersTable/UsersTable";
import { UserAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {getLinkProfiles} from '../../api/firestore';
const { Content, Footer } = Layout;

function MainScreen() {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getLinkProfiles(setTableData)
  }, []);

  return (
    <Layout className="layout">
      <Content className="main-screen-content">
        <Row justify="end" align="middle" className="addUser-container">
          {/* <Row > */}
          <Col>
            <div style={{ fontWeight: 300, paddingRight: "10px" }}>
              Add User
            </div>
          </Col>
          <Col>
            <Tooltip title="search">
              <Button
                onClick={() => history.push(`/user/new`)}
                style={{}}
                size="large"
                type="primary"
                shape="circle"
                icon={<UserAddOutlined />}
              />
            </Tooltip>
          </Col>
          {/* </Row> */}
          {/* <div style={{overflow:'hidden', width:'100px'}}><div style={{display:'block', float:'left'}}>Add User</div> <Button style={{float:'right'}} size="large" type="primary" shape="circle" icon={<UserAddOutlined />} /> </div> */}
        </Row>
        <div className="site-layout-content">
          <UsersTable dataSource={tableData} />
        </div>
      </Content>
    </Layout>
  );
}

export default MainScreen;
