import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  getDoc,
  doc,
  FieldValue,
  updateDoc,
  deleteDoc,
  arrayUnion,
  query,
  orderBy,
  limit,
  getDocs,
  Timestamp
} from "firebase/firestore";

async function setLinkProfile(obj, cb) {
  //this will create or update the doc.

  const db = getFirestore();
  const pathSegment = obj.path;
  const refName = "linkProfiles";

  let res;
  
  try {
    //First we try to update, if it fails then we create a new document.
    obj.updatedAt = Timestamp.now();
    res = await updateDoc(doc(db, refName, pathSegment), obj);
    cb(res);
  } catch (e) {
    console.error("Error adding document: ", e);
    console.error(JSON.stringify(e));
    if (e.code === "not-found") {
      obj.createdAt = Timestamp.now();
      res = await setDoc(doc(db, "linkProfiles", pathSegment), obj);

    }
  }
  cb(res);
}

async function getLinkProfile(path, cb) {
  const db = getFirestore();

  const docRef = doc(db, "linkProfiles", path);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return cb(docSnap.data());
  } else {
    return cb({});
  }
};
async function deleteLinkProile(path, cb) {
  const db = getFirestore();

  const docRef = doc(db, "linkProfiles", path);

  return cb(await deleteDoc(docRef))

};

async function getLinkProfiles(cb, limitCount = 100) {
  const db = getFirestore();

  const q = query(
    collection(db, "linkProfiles"),
    orderBy("createdAt", "desc"),
    limit(limitCount)
  );

  const docSnap = await getDocs(q);
  // docSnap.
  const arry = [];
  docSnap.forEach((doc) => {
    // console.log(doc,'wtf')
    arry.push(doc.data());
  });

  console.log(arry,'arry')
  return cb(arry);
}

export { setLinkProfile, getLinkProfile, getLinkProfiles, deleteLinkProile };
