import "./EditUserScreen.css";
import {
  Table,
  Typography,
  Button,
  Tooltip,
  Tag,
  Breadcrumb,
  Layout,
} from "antd";

import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";

import UserForm from "../../components/UserForm";

// const { Paragraph, Link } = Typography;
const { Content, Footer } = Layout;

function EditUserScreen() {
  const history = useHistory();

  let { path } = useParams();

  const user = {}; //TODO
  return (
    <Layout className="layout">
      <Content className="main-screen-content">
        <Breadcrumb separator=">">
          <Breadcrumb.Item href={null} onClick={() => history.push("/")}>
            Users
          </Breadcrumb.Item>
          <Breadcrumb.Item href={null}>{path}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-content site-layout-content-edit-user-screen">
          <UserForm user={user} path={path} />
        </div>
      </Content>
    </Layout>
  );
}

export default EditUserScreen;
