import "./UsersTable.css";
import { Table, Typography, Button, Tooltip, Tag } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import React, { useState, useEffect, useContext } from "react";

const { Paragraph, Link } = Typography;

// const dataSource = [
//   {

//     name: "Lino Suarez",
//     path: "linosuarez",
//     links: [
//       { name: "Facebook", link: "https://fb.com/" },
//       { name: "Youtube", link: "https://fb.com/" },
//       { name: "Instagram", link: "https://instagram.com/" },
//     ],
//   },
//   {

//     name: "Louis Barraza",
//     path: "louisbarraza",
//     links: [
//       { name: "Facebook", link: "https://fb.com/" },
//       { name: "Youtube", link: "https://fb.com/" },
//       { name: "Instagram", link: "https://instagram.com/" },
//     ],
//   },
// ];

function UsersTable({dataSource}) {
  console.log(dataSource,'dataSource1')
  const history = useHistory();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
      render: (data) => {
        const url = 'https://tplink.me/' + data;
        return (
          <Link target="_blank" href={url} copyable>
            {url}
          </Link>
        );
      },
    },
    {
      title: "Links",
      dataIndex: "linksList",
      key: "linksList",
      render: (data) => {
        return data.map(({ name, url }) => {
          return (
            <Tag color="blue" key={name + url}>
              <a target="_blank" href={url}>
                {name}
              </a>
            </Tag>
          );
        });
      },
    },
    {
      title: "Actions",
      render: (data) => {
        return (
          <div>
            <Tooltip title="Edit">
              <Button
                onClick={() => history.push(`/user/${data.path}`)}
                shape="circle"
                icon={<EditTwoTone />}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return <Table dataSource={dataSource} columns={columns} />;
}

export default UsersTable;
