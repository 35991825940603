var firebaseConfig = {
  apiKey: "AIzaSyBDHRq57nkP1ZK41Izx8P-snakxGDYlf98",
  authDomain: "monetize-links.firebaseapp.com",
  databaseURL: "https://monetize-links.firebaseapp.com",
  projectId: "monetize-links",
  storageBucket: "monetize-links.appspot.com",
  messagingSenderId: "91889469355",
  appId: "1:91889469355:web:76ae7119bf2b216f26b4ee",
};

export default firebaseConfig;