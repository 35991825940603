import "./UserForm.css";

import { Form, Input, Button, Checkbox, Skeleton } from "antd";
import { useHistory } from "react-router-dom";

import LinkForm from "./LinksForm/LinksForm";
import { useState, useEffect } from "react";

import {
  getLinkProfile,
  setLinkProfile,
  deleteLinkProile,
} from "../../api/firestore";

import { deleteCache } from '../../api/cache'

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 12,
  },
};

const UserForm = ({ path }) => {
  const history = useHistory();

  const [formState, setFormState] = useState({});
  const [linksList, setLinksList] = useState([]);
  const [finishLoading, setFinishLoading] = useState(false);
  const [formSaved, setFormSaved] = useState(true);
  useEffect(() => {
    //path === new means a new user is going to be created, no need to query backend for user info.
    if (!isNewUser()) {
      getLinkProfile(path, (data) => {
        setFormState({ ...formState, ...data });
        setLinksList([...linksList, ...data.linksList]);
        setFinishLoading(true);
        console.log(data);
      });
    }

  }, [path]);

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleChange(evt) {
    const value = evt.target.value;
    console.log(evt.target.name, value);
    setFormState({
      ...formState,
      [evt.target.name]: value,
    });
  }

  function submitForm() {
    setFormSaved(false);
    const obj = { ...formState, linksList };
    setLinkProfile(obj, () => {

      setFormSaved(true);
    });
  }
  function submitDeleteUser() {
    const path = formState.path;
    deleteLinkProile(path, () => {
      history.push("/");
    });
  }
  function submitDeleteCache() {
    const path = formState.path;
    deleteCache(path);
  }

  function isNewUser() {
    return path === "new";
  }

  return (
    <div className="user-form-container">
      {finishLoading || isNewUser() ? (
        <Form
          {...layout}
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          id="edit-user-form"
        >
          <Form.Item
            label="Link Path"
            // name="path"

            rules={[
              {
                required: true,
                message: "Please input your path!",
              },
            ]}
          >
            <Input
              name="path"
              onChange={handleChange}
              value={formState.path}
              addonBefore="/"
              disabled={formState.path !== undefined && !isNewUser()}
              // addonBefore="http://example.com/"
              placeholder="example"
            />
          </Form.Item>
          <Form.Item
            label="Display Name"
            // name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input
              name="name"
              id="name"
              onChange={handleChange}
              defaultValue={formState.name}
              value={formState.name}
              placeholder="display dame"
            // value={"Hello value"}
            />
          </Form.Item>
          <Form.Item
            label="Image URL"
            // name="name"
            rules={[
              {
                required: true,
                message: "Please input the profile picture URL!",
              },
            ]}
          >
            <Input
              name="imageURL"
              id="imageURL"
              onChange={handleChange}
              defaultValue={formState.imageURL}
              value={formState.imageURL}
              placeholder="https://example.com/img/1.png"
            />
          </Form.Item>
          <Form.Item>
            <LinkForm linksList={linksList} setLinksList={setLinksList} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => submitForm()}
              loading={!formSaved}
            >
              Save
            </Button>
            <Button danger htmlType="button" onClick={() => submitDeleteUser()}>
              Delete
            </Button>
            <Button danger htmlType="button" onClick={() => submitDeleteCache()}>
              Delete Cache
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
};

export default UserForm;
