import axios from 'axios';

function deleteCache(path) {
    return axios.get('https://api.tplink.me/api/v1/cache/delete/profile/' + path).then(({data}) => {
        console.log(data)
        return data
    })
}

export {
    deleteCache
};