import "./MainNav.css";
import { Layout, Menu, Button, Typography } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import firebaseAPI from "../../api/firebase";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";

import {UserContext} from '../../context/userContext';

const { Header } = Layout;

function currentRouteIndex(path) {
  switch (path) {
    case "/":
      return 1;
    case "/actions_log":
      return 2;
  }

  //dynamic
  if (path.includes('/user/')) {
    return 1;
  }

  return 1;

}

export default function MainNav(props) {
  const location = useLocation();
  const history = useHistory();

  const {user} = useContext(UserContext);

  const [selectedItem, setSelectedItem] = useState(
    currentRouteIndex(location.pathname)
  );

  function navigate(index) {
    switch (index) {
      case 1:
        setSelectedItem(index);
        history.push("/");

        break;
      case 2:
        setSelectedItem(index);
        history.push("/actions_log");
        break;
    }
  }

  return (
    <Header>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={selectedItem.toString()}
        style={{display:'block'}}
        className="main-menu"
      >
        <Menu.Item eventkey="1" key="1" onClick={() => navigate(1)}>
          Users
        </Menu.Item>


        <div style={{ float: "right" }}>
          <Typography.Text style={{color:'rgba(255, 255, 255, 0.65)', paddingRight:15}}>{user?.email}</Typography.Text>
          <Button
            onClick={() => firebaseAPI.userSignOut()}
            type="primary"
            ghost="true"
            shape="round"
            icon={<LogoutOutlined />}
            size={18}
            className="tableButton"
          >
            Log Out
          </Button>
        </div>
   
      </Menu>
    </Header>
  );
}
